import fetch from "isomorphic-unfetch";
import { useMemo } from "react";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  NormalizedCacheObject,
  createHttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";

export function getSSRClient(token?: string) {
  const ssrHttpLink = createHttpLink({
    uri: process.env.GRAPHQL_ENDPOINT,
    fetch,
  });

  const authLink = setContext(async (_, { headers }) => {
    if (token) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    }

    return {
      headers: {
        ...headers,
        "X-Hasura-Role": "public",
      },
    };
  });

  const link = from([authLink, ssrHttpLink]);

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link,
    cache: new InMemoryCache(),
  });
}

function createApolloClient() {
  const httpLink = createHttpLink({
    // local graphql so that we can attch bearer token
    uri: "/api/graphql",
    fetch,
  });

  return new ApolloClient({
    ssrMode: typeof window === "undefined", // set to true for SSR
    link: httpLink,
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });
}

let apolloClient: ApolloClient<NormalizedCacheObject>;
export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  if (initialState) {
    const existingCache = _apolloClient.extract();
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  if (typeof window === "undefined") return _apolloClient;

  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

export function useApollo(initialState: any) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
