// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { CaptureConsole } from '@sentry/integrations';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  enabled: process.env.VERCEL_ENV === 'production',
  dsn: SENTRY_DSN || 'https://437c64d7376243f694877355d703423e@o1118215.ingest.sentry.io/6152003',
  tracesSampleRate: 1.0,
  integrations: [
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
});
