import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

if (typeof window !== "undefined" && process.env.VERCEL_ENV === "production") {
  LogRocket.init(process.env.NEXT_PUBLIC_LOG_ROCKET_APP_ID);
  setupLogRocketReact(LogRocket);
}

export function identify(userId: string, name: string, email: string) {
  LogRocket.identify(userId, {
    name,
    email,
  });
}
