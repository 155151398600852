import Analytics from "analytics";
import mixpanelPlugin from "@analytics/mixpanel";

const plugins = [];
if (process.env.VERCEL_ENV === "production") {
  plugins.push(
    mixpanelPlugin({
      token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
    }),
  );
}

const analytics = Analytics({
  app: "grizzly-challenge",
  version: "1",
  plugins,
});

export default analytics;
